export const wrapTextToLines = (text: string, maxChars: number, maxLines: number): string[] => {
    const words = text.split(" ");
    const lines: string[] = [];
    let currentLine = "";

    words.forEach(word => {
        if (currentLine === "" && word.length > maxChars){
            lines.push(word);
        } else if ((currentLine + word).length > maxChars) {
            lines.push(currentLine.trim());
            currentLine = word + " ";
        } else {
            currentLine += word + " ";
        }
    });

    if (currentLine.trim().length > 0) {
        lines.push(currentLine.trim());
    }

    if (lines.length > maxLines) {
        const truncatedLines = lines.slice(0, maxLines);
        let lastLine = truncatedLines[maxLines - 1];
        const ellipsis = "...";
        const availableSpace = maxChars - 3;

        if (lastLine.length > availableSpace) {
            const wordsInLastLine = lastLine.split(" ");
            lastLine = "";

            for (const word of wordsInLastLine) {
                if ((lastLine + word).length + 1 > availableSpace) break;
                lastLine += word + " ";
            }

            lastLine = lastLine.trim();
        }

        truncatedLines[maxLines - 1] = lastLine + ellipsis;
        return truncatedLines;
    }

    return lines;
};